import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import imageFence from '../../../images/residential/fence-line-v2.jpg';
import imageRpr from '../../../images/residential/rpr.jpg';
import '../../../styles/media.css';

const ResidentialSurveysIndex = () => (
  <Layout
    title="Residential Surveys Calgary & Red Deer | Axiom Geomatics"
    description="Homeowners often require a Real Property Report or Property Line Survey for landscaping or title transfer purposes."
    keywords="rpr, real property report, property survey, property line survey, residential survey, boundary survey, survey plan, plot plan, locating property pins, property posts"
  >
    <main>
      <h1>Residential Surveys Calgary & Area</h1>
      <p>
        Residential surveys include all surveys that a homeowner commonly requires. Axiom Geomatics
        offers the following competitive services for our residential clients in Calgary and area:
      </p>

      <div className="media-list">
        <Link to="/services/residential-surveys/real-property-reports">
          <img
            src={imageRpr}
            alt="Real Property Report sample"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/residential-surveys/real-property-reports">
            Real Property Reports
          </Link>
        </h2>
        <p>
          A Real Property Report (RPR) is a legal document showing information about a property
          including dimensions, permanent features, easements, right-of-ways and encroachments
          relative to property lines. In Alberta it is often required when real estate is sold.
        </p>

        <p className="align-right">
          <Link to="/services/residential-surveys/real-property-reports">
            Learn about Real Property Reports
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/residential-surveys/property-line-surveys">
          <img
            src={imageFence}
            alt="Beautiful fence"
            className="img img-left"
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/residential-surveys/property-line-surveys">
            Property & Fence Line Surveys
          </Link>
        </h2>
        <p>
          A Property Line Survey accurately identifies where property lines are, then they are
          clearly marked on the ground (staked out) enabling easy identification of what structures
          are on who's property and/or where to build a new structure, such as a fence.
        </p>

        <p className="align-right">
          <Link to="/services/residential-surveys/property-line-surveys">
            Learn about Property & Fence Line Surveys
          </Link>
        </p>
      </div>
    </main>
  </Layout>
);

export default ResidentialSurveysIndex;
